<template>
  <FocusTrap>
    <div  id="cardwindow" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 10px;" >
        <h5 v-if="voucher.id == 0" class="card-title">Digital Payment Creation</h5>
        <h5 v-if="voucher.id > 0" class="card-title"> Doc No: {{voucher.doc_no}}  Digital Payment Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" @click="loadData" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeThis"></a>
          </div>
        </div>
      </div>


      <div class="card-body">

      <div class="row">

        <div class="col-md-3">

<!--          <div class="form-group form-group-material">-->
<!--            <label class="control-label text-semibold">Paid Ledger</label>-->
<!--            <select id="cmbledger" class="form-control select" autofocus="" required="" v-if="voucher" v-model="voucher.ledger.id" readonly="" >-->
<!--              <option v-for="ledger in ledgers" v-bind:value="ledger.id">-->
<!--                {{ ledger.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->

        </div>
        <div class="col-md-7">

        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Date</label>
            <input id="txtDate" type="date" class="form-control" v-if="voucher" v-model="voucher.doc_date">
          </div>
        </div>
      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">
          <table id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
            <tr>
              <th style="width:50px;">S.No</th>
              <th >Particulars</th>
              <th >Description</th>
              <th style="width:150px; text-align: right;">Amount</th>
              <th style="width: 30px;">Action</th>
            </tr>

            </thead>
            <tbody >
              <tr v-model="voucher" v-for="(detail,index) in voucher.list" >
                <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>
                <td style="padding: 0px;">
                  <select class="form-control" v-if="detail" v-model="detail.ledger.id" >
                    <option v-for="ledger in deailledgers" v-bind:value="ledger.id">
                      {{ ledger.name }}
                    </option>
                  </select>
                </td>
                <td style="padding: 0px;">
                  <input type="text" class="form-control" placeholder="Description" maxlength="100"  v-if="detail" v-model="detail.desc" />
                </td>
                <td style="padding: 0px;">
                  <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.debit" @change="sumRowAmountTotal" />
                </td>
                <td style="padding: 0px;text-align: right">
                  <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot>
            <tr>
              <td style="padding: 0px;">
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>

              <td class="text-right" >&#8377;<span class="font-weight-semibold" style="padding-right: 0px;">{{ indianFormat(rowAmountTotal) }}</span></td>

              <td></td>

            </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <!-- / Detail Table -->

      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label text-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="voucher" v-model="voucher.remarks" > </textarea>
          </div>
        </div>

        <div class="col-md-6">
        </div>

        <div class="col-md-3 text-right">
          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
import Vue from 'vue';
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'ExpenseForm',
  store,
  components: {

  },
  data () {
    return {
      readonly: false,
      ledgers: [],
      deailledgers: [],
      rowAmountTotal: 0.0,
      voucher: JSON.parse('{"id":0,"type":0,"status": "Active","finyear":0,"iscredit":false,"vchtype":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"status": "Active","type":0,"name":"","alias":"","group":{"id":0,"status": "Active","type":0,"name":"","alias":"","parent":0,"nature":0,"level":0,"is_profit":false,"is_subledger":false,"is_net_bal":false},"add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""},"open_bal":0,"gstin":""},"amount":0,"remarks":"","ibr":0,"locked":false,"list":[]}')
    }
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"type":0,"status": "Active","finyear":0,"iscredit":false,"vchtype":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"status": "Active","type":0,"name":"","alias":"","group":{"id":0,"status": "Active","type":0,"name":"","alias":"","parent":0,"nature":0,"level":0,"is_profit":false,"is_subledger":false,"is_net_bal":false},"add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""},"open_bal":0,"gstin":""},"amount":0,"remarks":"","ibr":0,"locked":false,"list":[]}')
    }
  },
  beforeMount () {
    this.voucher = this.myvoucher;
    this.sumRowAmountTotal();
  },
  created () {
    this.$data.voucher.doc_date = moment().format('YYYY-MM-DD')
    this.$data.voucher.ref_date = moment().format('YYYY-MM-DD')
  },
  mounted () {
    const self = this;
    self.loadData();

    if (self.$data.voucher.doc_date == '0001-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD')
    }

    $('#txtDate').focus();

  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2)
    },
    closeThis () {
      this.$emit('vault_window_closed');
      if (this.voucher.id > 1) {
        this.$modal.hide('voucher-window');
        this.voucher = JSON.parse('{"id":0,"type":0,"status": "Active","finyear":0,"iscredit":false,"vchtype":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"status": "Active","type":0,"name":"","alias":"","group":{"id":0,"status": "Active","type":0,"name":"","alias":"","parent":0,"nature":0,"level":0,"is_profit":false,"is_subledger":false,"is_net_bal":false},"add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""},"open_bal":0,"gstin":""},"amount":0,"remarks":"","ibr":0,"locked":false,"list":[]}')
      } else {
        this.$router.push('/');
      }
    },
    addRow () {
      const self = this;
      self.$data.voucher.list.push(JSON.parse('{"idx": 0, "adjusttype": 0, "ledger": { "id": 0, "status": 0, "type": 0, "name": "", "alias": "", "group": { "id": 0, "status": 0, "type": 0, "name": "", "alias": "", "parent": 0, "nature": 0, "level": 0, "is_profit": false, "is_subledger": false, "is_net_bal": false}, "add": {"name": "", "street": "", "city": "", "pin": "", "state_id": 0, "email": "", "tel": "", "mobile": ""}, "open_bal": 0, "gstin": ""}, "desc": "", "debit": 0, "credit": 0}'))

      setTimeout(function(){
        $("tbody>tr").last().find("td:eq(1) select").focus();
      }, 99);
    },
    sumRowAmountTotal () {
      const self = this;

      self.$data.rowAmountTotal = 0.0;
      self.$data.voucher.list.forEach(function (detail) {
        self.$data.rowAmountTotal += parseFloat(detail.debit)
      });

    },
    clear () {
      const self = this;
      self.$data.voucher = JSON.parse('{"id":0,"type":0,"status": "Active","finyear":0,"iscredit":false,"vchtype":0,"doc_no":"","doc_date":"0001-01-01","ref_no":"","ref_date":"0001-01-01","ledger":{"id":0,"status": "Active","type":0,"name":"","alias":"","group":{"id":0,"status": "Active","type":0,"name":"","alias":"","parent":0,"nature":0,"level":0,"is_profit":false,"is_subledger":false,"is_net_bal":false},"add":{"name":"","street":"","city":"","pin":"","state_id":0,"email":"","tel":"","mobile":""},"open_bal":0,"gstin":""},"amount":0,"remarks":"","ibr":0,"locked":false,"list":[]}');
      self.$data.voucher.voucherDate = moment().format('YYYY-MM-DD');
      self.$data.voucher.referenceDate = moment().format('YYYY-MM-DD');
      self.$data.voucher.list = [];
      self.$data.rowAmountTotal = 0.0;
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.ref_date = moment().format('YYYY-MM-DD');
    },
    loadData () {
      const self = this;

      const requestOptions = {
        mode: 'cors',
        headers: userService.authHeader()
      }

      // fetch the Ledgers
      self.$data.ledgers = [];

      //v1/accounts/ledger/groups/12,22,23/
      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/xbunk/vault/ledgers/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.ledgers = resp.data;
          }
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      })

      // Fetch Detail Ledgers
      self.$data.deailledgers = []

      // `${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/groups/2,3,4,6,7,8,15,17,19,20,21,25,27,29,30,31,35,38,45/`
      //v1/accounts/ledger/natures/1,2,4/
      fetch(`${process.env.VUE_APP_ROOT_API}api/v1/accounts/ledger/`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          self.$data.deailledgers = resp.data
        } else {
          swal({ title: 'Oops', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oops', text: err.toString(), type: 'error' })
      })
    },
    removeRow (index) {
      if (index > -1) {
        this.$data.voucher.list.splice(index, 1);
        this.sumRowAmountTotal();
      }
    },
    saveVoucher () {
      const self = this;

      try {

        let detail = JSON.parse('{"idx": 0, "adjusttype": 0, "ledger": { "id": 0, "status": 0, "type": 0, "name": "", "alias": "", "group": { "id": 0, "status": 0, "type": 0, "name": "", "alias": "", "parent": 0, "nature": 0, "level": 0, "is_profit": false, "is_subledger": false, "is_net_bal": false}, "add": {"name": "", "street": "", "city": "", "pin": "", "state_id": 0, "email": "", "tel": "", "mobile": ""}, "open_bal": 0, "gstin": ""}, "desc": "", "debit": 0, "credit": 0}');

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher));

        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.vchtype = 1005;
        myvoucher.ibr = parseInt(self.$store.state.user.branch.id);
        myvoucher.brn_id = parseInt(self.$store.state.user.branch.id);
        myvoucher.iscredit = false;
        myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');// + 'T00:00:00Z'
        myvoucher.ref_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');// + 'T00:00:00Z'
        myvoucher.ledger.id = parseInt(self.$store.state.user.userledger.id);
        myvoucher.amount = parseFloat(self.$data.rowAmountTotal);

        //User Ledger Detail
        // detail.ledger.id = parseInt(self.$store.state.xpos.userledger.id);
        // detail.credit = parseFloat(self.$data.rowAmountTotal);
        // myvoucher.list.push(detail);

        myvoucher.list.forEach(function (detail) {
          detail.credit = 0.0// parseFloat(detail.credit);
          detail.debit = parseFloat(detail.debit);
        });

        const requestOptions = {
          method: ((myvoucher.id == 0) ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        $('#cardwindow').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        $('#txtDate').focus();
        fetch(`${process.env.VUE_APP_ROOT_API}api/v1/accounts/svoucher/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success', type: 'success', text: resp.msg, onClose: () => {
                $('#txtDate').focus()
              }, timer: 1500
            });
            self.clear();
            self.$emit('vault_saved', resp.data);

          } else {
            swal({
              title: 'Oops', text: resp.msg, type: 'error', onClose: () => {
                $('#txtDate').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oops', text: err.toString(), type: 'error', onClose: () => {
              $('#txtDate').focus()
            }, timer: 3000
          });
        }).finally(function () {
          $('#cardwindow').unblock();
        });
      }catch (e) {
        alert(e);
      }

    },

  }
}
</script>


<style scoped>

  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
